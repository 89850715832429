.movies {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-color: brown;
    /* background-image: url('https://images.unsplash.com/photo-1489599849927-2ee91cede3ba?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80'); */
}
.moviecard {
    display: flex;
    flex-direction: column;
    width: 20%;
    justify-content: center;
    align-items: center;
    margin: 2%;
    border: 1px solid black;
}
.LastPage {
    display: flex;
    justify-content: space-between;
    padding: 2%;

}
span {
    display: flex;

    align-items: center;

}
img {
    width: 100%;
}
.poster{
    height: 120%;
    width: 100%;
}